import React, { Component } from 'react';
import ImageResto from '../images/galeriephotos5.jpg';
import MenuJour from '../images/220201_MenuDeLaSemaine.jpeg';
import Promotion1 from '../images/Promo-du-mois.jpeg';
import Promotion2 from '../images/CHASSE.png';
import Promotion3 from '../images/Reveillon.jpg';
import Breaks from '../images/break.jpg';
import Buffet from '../images/buffet.jpeg';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import Flyer from '../images/Flyer_Verso.jpeg';
import {Helmet} from "react-helmet";
import { gapi } from 'gapi-script';


// Style pour la pop-up
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      textAlign             : 'center',
      backgroundColor       : '#e8f5e9',
      border                : '2px solid',
    }
  };


  // State initial pour le formulaire
const initialState = {
    prenom: "",
    nom: "",
    phone: "",
    email: "",
    prenomError: "",
    nomError: "",
    emailError: "",
    show: false
}

const SPREADSHEET = '1XxThEjYj6SK3bwcByaR0zk60yP8yz26yiJM21czPCMk';
const CLIENT_ID = '826545001778-ojtcgn5jstobbd9tfg2ku6637in04tgn.apps.googleusercontent.com';
const API_KEY = 'AIzaSyDndn7LWHsPfOQ3rChl2O4oWL6nJRGQGbY';
const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';



class Accueil extends Component {

    

    componentDidMount() {

        this.handleClientLoad();

        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);

        let elems = document.querySelector('.autocomplete');
        M.Autocomplete.init(elems, {});

        // Ouvre la pop-up après 20 secondes
        setTimeout(() => {
            this.setState({ show: true });
        }, 20000);
        
      }

      handleClientLoad =()=> { //initialize the Google API
        gapi.load('client:auth2', this.initClient);
      }

      initClient =()=> { //provide the authentication credentials you set up in the Google developer console
        gapi.client.init({
          'apiKey': API_KEY,
          'clientId': CLIENT_ID,
          'scope': SCOPE,
          'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        }).then(()=> {
          gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSignInStatus); //add a function called `updateSignInStatus` if you want to do something once a user is logged in with Google
          this.updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        });
      }

    // Initialise les states
    state = initialState;


    // Ferme la pop-up
    closeModal = () => {
        this.setState({ show: false });
    };

    // Permet de prendre en compte les modifications réalisées dans les champs du formulaire.
    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
        [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
    };


    // Fonction pour valider le formulaire
    validate = () => {
        let prenomError = "";
        let nomError = "";
        let emailError = "";

        if (!this.state.nom) {
        nomError = "Ce champ ne peut pas être vide";
        }

        if (!this.state.prenom) {
        prenomError = "Ce champ ne peut pas être vide";
        }

        if (!this.state.email) {
        emailError = 'Votre adresse mail est invalide';
        }

        if (emailError || nomError || prenomError){
        this.setState({emailError, nomError, prenomError });
        return false;
        }

        return true;
    };


    // Fonction pour envoyer le formulaire
    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid){
    
            this.setState({ showModal: true });
    
          // Envoie le mail
          emailjs.sendForm('service_11watlr', 'template_sj89qrf', event.target, 'user_qQOT5QYIZNiX0dgJsOt28'); 
          
          // Clear le formulaire s'il est valide, après l'avoir envoyé
          this.setState(initialState);
        }
    
      };


      constructor (props) {
        super(props);
        this.testExcel = this.testExcel.bind(this);
        this.state = {
          show: false,
          showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        
      
      };

      handleOpenModal () {
        this.setState({ showModal: true });
      }
      
      handleCloseModal () {
        this.setState({ showModal: false });
      }

      testExcel(submissionValues){
        const params = {
            // The ID of the spreadsheet to update.
            spreadsheetId: SPREADSHEET, 
            // The A1 notation of a range to search for a logical table of data.Values will be appended after the last row of the table.
            range: 'Sheet1', //this is the default spreadsheet name, so unless you've changed it, or are submitting to multiple sheets, you can leave this
            // How the input data should be interpreted.
            valueInputOption: 'RAW', //RAW = if no conversion or formatting of submitted data is needed. Otherwise USER_ENTERED
            // How the input data should be inserted.
            insertDataOption: 'INSERT_ROWS', //Choose OVERWRITE OR INSERT_ROWS
          };

          const valueRangeBody = {
            'majorDimension': 'ROWS', //log each entry as a new row (vs column)
            'values': [submissionValues] //convert the object's values to an array
          };

          let request = gapi.client.sheets.spreadsheets.values.append(params, valueRangeBody);
    request.then(function (response) {
      // TODO: Insert desired response behaviour on submission
      console.log(response.result);
    }, function (reason) {
      console.error('error: ' + reason.result.error.message);
    });
  
      }

      
        
      



    render(){



    return (

        


        <div>

        
            
        

        <div style={{ backgroundImage:`url(${ImageResto})`, backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
        




            <div className="container">


                        {/* Message de confirmation après fermeture de la pop-up*/}
                        
                        <Modal 
                            isOpen={this.state.showModal}
                            style={customStyles}
                        >
                  
                            <p className="textBase"  style={{fontSize:24}}>Vous êtes à présent inscrit à notre newsletter !</p>
                   
                            <button onClick={this.handleCloseModal} className="btn green darken-2">Confirmer</button>
                  
                
                        </Modal>



                
                        { /* Pop-up */}
                        <Modal 
                                style={customStyles}
                                isOpen={this.state.show}
                                >

                                <button onClick={this.closeModal} className="right btn-flat" style={{position: 'relative', alignSelf: 'right', verticalAlign:'right'}}>
                                    <i className="material-icons right">clear</i><br/>
                                </button><br/>
         

                                <p className="textBase"  style={{fontSize:20}}>

                                    Inscrivez-vous maintenant à notre newsletters !
                                    
                                </p>

                                <p  className="textBase" style={{fontSize:16}}>
                                    - Les nouveautés en avant-première.<br/>
                                    - Des offres exclusives.
                                
                                </p>

                                {/* onSubmit={this.testExcel} */}
                                <form className="contact-form" onSubmit={this.handleSubmit}>

                                    <div className="input-field">

                                        <i className="material-icons prefix">account_circle</i>

                                        <input
                                                        id="icon_prefix"
                                                        type="text"
                                                        className="validate"
                                                        name="prenom"
                                                        value={this.state.prenom}
                                                        onChange={this.handleChange}
                                                        placeholder="Prénom"
                                        />

                                        <div style={{ color:'red', fontWeight: 'bold'}}>
                                            {this.state.prenomError}
                                        </div>

                                    </div>

                                    <div className="input-field">

                                        <i className="material-icons prefix">account_circle</i>

                                        <input 
                                            id="icon_prefix"
                                            type="text"
                                            className="validate"
                                            name="nom"
                                            value={this.state.nom}
                                            onChange={this.handleChange}
                                            placeholder="Nom"
                                        />

                                        <div style={{ color:'red', fontWeight: 'bold'}}>
                                            {this.state.nomError}
                                        </div>

                                                    
                                    </div>


                                    <div className="input-field">

                                        <i className="material-icons prefix">phone</i>

                                        <input
                                            id="icon_telephone"
                                            type="tel"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                            placeholder="Téléphone (optionnel)"
                                        />

                                    
                                    </div>


                                        <div className="input-field">

                                            <i className="material-icons prefix">email</i>

                                            <input
                                                id="icon_email"
                                                name="email"
                                                className="validate"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                placeholder="Adresse e-mail"
                                            />

                                            <div style={{ color:'red', fontWeight: 'bold'}}>
                                                {this.state.emailError}
                                            </div>

                                                    
                                            <span className="helper-text" data-error="E-mail incorrect" data-success="E-mail correct"></span>


                                                    
                                        </div>

                                        <div className="input-field center">
                                            <button className="btn green darken-2" type="submit" value="Send">S'inscrire</button>
                                        </div>


                                </form>
                              
                                
                        </Modal>
                        { /* Fin Pop-up */}
                    
                        
                 

        

                
                

                {/* Affichage tablette + téléphone*/}
                <div className="hide-on-large-only"><br/>

                    <div className="row">
                        <p></p>
                        <div style={{ backgroundColor: "white", opacity:'90%', borderRadius: 35}} className="col s12">

                            <h4 className="center">Restaurant Mirabel Chez Francis</h4>
                            <p className="flow-text">Cuisine traditionnelle, spécialités indiennes et asiatiques à deux pas d'Yverdon</p>
                            <button className="btn green darken-2 waves-effect waves-dark">024 441 73 66</button><br/><br/>

                        </div>

                    </div>

                    <div className="row">

                        
                            <div style={{ opacity:'90%', borderRadius: 35, paddingBottom: 15}} className="col s10 offset-s1 green lighten-4">
                                <h4 className="center green-text text-darken-2">Heures d'ouverture</h4> 
                                <table className="highlight">
                                    <tbody>

                                        <tr>
                                            <td className="left-align textBase">Dimanche</td>
                                            <td className="right-align textBase">9h00 - 14h00  <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Lundi</td>
                                            <td className="right-align textBase">8h45 - 14h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Mardi</td>
                                            <td className="right-align textBase">8h45 - 14h00</td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="left-align textBase">Mercredi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Jeudi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Vendredi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Samedi</td>
                                            <td className="right-align textBase">9h00 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        

                    </div>
                    



                        <br/>
                </div>

            </div>
        </div>
        <div style={{ backgroundImage:`url(${ImageResto})`, backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
            
        {/* Affichage ordinateur*/}
        <div className="hide-on-med-and-down">
        <div className="container"><br/>


                        <div className="row" style={{ display: 'flex', alignItems: 'center'}}>                         

                            <div style={{ backgroundColor: "white", opacity:'90%', borderRadius: 35}} className="col l8">

                                    <h4 className="center">Restaurant Mirabel Chez Francis</h4>      
                                    <p className="flow-text">Cuisine traditionnelle, spécialités indiennes et asiatiques à deux pas d'Yverdon</p>
                                   
                                    <div style={{ opacity:'90%'}}>
                                    
                                        <button className="btn green darken-2 waves-effect waves-dark">024 441 73 66</button><br/><br/>

                                    </div>
                            </div>
                            
                            
                            <div style={{ opacity:'90%', borderRadius: 35, paddingBottom: 15}} className="col l4 green lighten-4">
                                <h4 className="center green-text text-darken-2">Heures d'ouverture</h4> 
                                <table className="highlight">
                                    <tbody>

                                        <tr>
                                            <td className="left-align textBase">Dimanche</td>
                                            <td className="right-align textBase">9h00 - 14h00  <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Lundi</td>
                                            <td className="right-align textBase">8h45 - 14h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Mardi</td>
                                            <td className="right-align textBase">8h45 - 14h00</td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="left-align textBase">Mercredi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Jeudi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Vendredi</td>
                                            <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>

                                        <tr>
                                            <td className="left-align textBase">Samedi</td>
                                            <td className="right-align textBase">9h00 - 14h00 <br/> 18h00 - 23h00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>                      

                        <br/>
                    </div>

                </div>
            </div>

            <div className="container">
<br/>
               

                <div className="row">

                
                    <div className="green lighten-5 col l6 offset-l3 s12" style={{borderRadius: 35}}>

                        <h4 className="center green-text text-darken-2">Découvrez dès maintenant notre Menu du Réveillon !</h4>
                        
                        <div className="row">

                            <div className="col l10 s12 offset-l1">

                                <img src={Promotion3} alt="MenuDuJour" className="responsive-img materialboxed" />

                            </div>

                        </div>
                    
                    </div>

                    
                </div>

                <div className="row">

                
                    <div className="green lighten-5 col l6 offset-l3 s12" style={{borderRadius: 35}}>

                        <h4 className="center green-text text-darken-2">La promotion du mois est disponible !</h4>
                        
                        <div className="row">

                            <div className="col l10 s12 offset-l1">

                                <img src={Promotion1} alt="MenuDuJour" className="responsive-img materialboxed" />

                            </div>

                        </div>
                    
                    </div>

                    
                </div>

                <div className="row">

                
                    <div className="green lighten-5 col l6 offset-l3 s12" style={{borderRadius: 35}}>

                        <h4 className="center green-text text-darken-2">La chasse est ouverte !</h4>
                        
                        <div className="row">

                            <div className="col l10 s12 offset-l1">

                                <img src={Promotion2} alt="MenuDuJour" className="responsive-img materialboxed" />

                            </div>

                        </div>
                    
                    </div>

                    
                </div>
                              
                
              

                    <h4 className="green-text text-darken-2">Menus du jour </h4>
                    <p className="flow-text green-text text-darken-2">Tous nos plats du jour sont servis avec une salade de saison en entrée ou un potage</p>
                    

                    <div className="row">

                        <div className="col l10 s12 offset-l1">

                            <img src={MenuJour} alt="MenuDuJour" className="responsive-img materialboxed" />

                        </div>

                    </div>

                    
                    

                    <div className="green lighten-5" style={{borderRadius: 35}}>

                        <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Plats à l'emporter</h4>
                        
                        <p className="textBase" style={{paddingBottom: 15, paddingLeft: 15, paddingRight: 15,}}>A titre d'information, tous les plats proposés dans ce restaurant sont également disponibles à emporter.<br/>
                        Les plats à l'emporter comportent un rabais de 10% pour tous les plats
                        à la carte ainsi que 2 CHF de rabais pour les pizzas à l'emporter.<br/>
                        Toutefois, la livraison est assurée uniquement sur place.</p>
                        
                        
                    </div>


                    
                    

                    <div className="green lighten-5" style={{borderRadius: 35}}>

                        <h4 className="green-text text-darken-2" style={{paddingTop: 15}}>MENUS COMPLETS</h4>

                        {/* Affichage ordinateur*/}
                        <div className="hide-on-med-and-down">

                            <div className="row">

                                <div className="col l4 offset-l1 textBaseBold">

                                    <h4>Menu 1</h4>
                                    <p>Salade de saison</p>
                                    <p>***</p>
                                    <p>Filets mignons</p>
                                    <p>Sauce aux morilles</p>
                                    <p>Frites, légumes</p>
                                    <p>***</p>
                                    <p>Dessert maison</p>
                                    <p>***</p>
                                    <p>CHF 34.50</p>

                                </div>


                                <div className="col l4 offset-l2 textBaseBold">
                                    <h4>Menu 2</h4>
                                    <p>Salade de saison</p>
                                    <p>***</p>
                                    <p>Filets de perche</p>
                                    <p>Sauce tartare</p>
                                    <p>Frites, légumes</p>
                                    <p>***</p>
                                    <p>Dessert maison</p>
                                    <p>***</p>
                                    <p>CHF 32.50</p>
                                </div>


                            </div>

                        </div>

                        {/* Affichage tablette + téléphone*/}
                        <div className="hide-on-large-only">

                        <div className="row">

                                <div className="col s4 offset-s1 textBaseBold">

                                    <h4>Menu 1</h4>
                                    <p>Salade <br/>de <br/>saison</p>
                                    <p>***</p>
                                    <p>Filets mignons</p>
                                    <p>Sauce morilles</p>
                                    <p>Frites, légumes</p>
                                    <p>***</p>
                                    <p>Dessert maison</p>
                                    <p>***</p>
                                    <p>CHF 34.50</p>

                                </div>


                                <div className="col s4 offset-s2 textBaseBold">
                                    <h4>Menu 2</h4>
                                    <p>Rouleaux <br/>de <br/>printemps</p>
                                    <p>***</p>
                                    <p>Filets de perche</p>
                                    <p>Sauce tartare</p>
                                    <p>Frites, légumes</p>
                                    <p>***</p>
                                    <p>Dessert maison</p>
                                    <p>***</p>
                                    <p>CHF 32.50</p>
                                </div>


                            </div>

                        </div>

                    </div>

                    {/* Affichage tablette + téléphone*/}
                    <div className="hide-on-large-only"><br/>

                        <div className="row">

                            <div className="col s12">

                            <div style={{ opacity:'90%'}}>

                                <NavLink to="/entree">
                                    <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète</button>
                                </NavLink>

                            </div>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col s12">

                            <div style={{ opacity:'90%'}}>

                                <NavLink to="/contact">
                                    <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                                </NavLink>

                            </div>

                            </div>

                        </div>

                    </div>

                    {/* Affichage ordinateur*/}
                    <div className="hide-on-med-and-down">


                        <div className="row">

                            <div className="col l4 offset-l1 s6">

                            <div style={{ opacity:'90%'}}>

                                <NavLink to="/entree">
                                    <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète</button>
                                </NavLink>

                            </div>

                            </div>

                            <div className="col l4 offset-l2 s6">

                            <div style={{ opacity:'90%'}}>

                                <NavLink to="/contact">
                                    <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                                </NavLink>

                            </div>

                            </div>

                        </div>

                        
                    </div>

                    <br/>

                    

               </div>     
               <img src={Buffet} alt="Buffet" className="responsive-img materialboxed" />

                    
            </div>
        
    )
    }
}

export default Accueil;